import React, { lazy } from 'react';
import styled from 'styled-components';

/* Common hocs */

const hocs = {
  Protected: lazy(() => import('./components/hoc/Protected')),
  DsSettings: lazy(() => import('./components/hoc/DsSettings')),
  Ds: lazy(() => import('./components/hoc/Ds')),
  DsPreviewPublicRoot: lazy(() => import('./components/hoc/DsPreviewPublic'))
};

/* Common layout */

const layout = {
  Navbar: lazy(() => import('./components/layout/Navbar')),
  Container: lazy(() => import('./components/ui/Container'))
};

/* Common features */

const features = {
  Modal: lazy(() => import('./components/features/modal')),
  Gallery: lazy(() => import('./components/features/gallery')),
  Correction: lazy(() => import('./components/features/correction')),
  Attachment: lazy(() => import('./components/features/attachments')),
  AttachmentPublic: lazy(
    () => import('./components/features/attachments/AttachmentPublic')
  ),
  AttachmentMissingSelectedError: lazy(
    () => import('./components/features/attachments/AttachmentMissingSelectedError')
  )
};

/* Common pages */

const pages = {
  NotFound: lazy(() => import('./components/pages/NotFound')),
  NoAccess: lazy(() => import('./components/pages/NoAccess')),
  Approval: lazy(() => import('./components/pages/Approval')),
  Approved: lazy(() => import('./components/pages/Approved')),
  DsCorrectionApproved: lazy(
    () => import('./components/features/correction/pages/Approved')
  ),
  DsSellerMadeCorrection: lazy(
    () => import('./components/features/correction/pages/SellerMadeCorrection')
  ),
  DsCorrectionPrint: lazy(
    () => import('./components/features/correction/pages/CorrectionPrint')
  ),
  PreviewPublic: lazy(() => import('./components/pages/PreviewPublic')),
  DsPreview: lazy(() => import('./components/pages/Preview')),
  DsPreviewPublic: lazy(() => import('./components/pages/TemplatePage')),
  Error: lazy(() => import('./components/pages/Error')),
  Estate: lazy(() => import('./components/pages/Estate')),
  ProjectPricelist: lazy(() => import('./components/pages/ProjectPricelist'))
};

/* Common ui */

const ui = {
  Button: lazy(() => import('./components/ui/Button'))
};

const utils = {
  Seo: lazy(() => import('./components/utils/Seo')),
  PreviewNotification: lazy(() => import('./components/ui/PreviewNotification'))
};

/* Templates */

const templates: { [key: string]: any } = {
  privatmegleren: lazy(() => import('./components/templates/privatmegleren/Index')),
  emvest: lazy(() => import('./components/templates/emvest/Index')),
  garanti: lazy(() => import('./components/templates/garanti/Index')),
  rele: lazy(() => import('./components/templates/rele/Index')),
  beleven: lazy(() => import('./components/templates/beleven/Index')),
  ['wle.partners']: lazy(() => import('./components/templates/partners/Index')),
  ['wle.stavlund']: lazy(() => import('./components/templates/partners/Index')),
  ['wle.tinholt']: lazy(() => import('./components/templates/partners/Index')),
  ['wle.eiendomsmeglerne']: lazy(() => import('./components/templates/partners/Index'))
};

const templatesPricelist: { [key: string]: any } = {
  emvest: lazy(() => import('./components/templates/emvest/pricelist/Index'))
};

const Template = lazy(() => import('./components/pages/Template'));
const TemplatePricelist = lazy(() => import('./components/pages/TemplatePricelist'));
const TemplateContextProvider = lazy(() => import('./components/context/Template'));

const template = {
  TemplateContextProvider,
  Template,
  TemplatePricelist
};

export {
  hocs,
  layout,
  features,
  pages,
  ui,
  utils,
  template,
  templates,
  templatesPricelist
};
